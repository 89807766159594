import React from "react";

import LessonLayout from "~/layouts/lesson";

import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";

const Page = () => {
  return (
      <LessonLayout
        current_step={8}
        lesson={"Lesson 2"}
        color={"ms"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 8</H2>

          <p>In Lesson 1, you used a rubric to evaluate information about website features. Now, you will continue working with the rubric.</p>
          <p>Get the rubrics you started in Lesson 1. Notice in the "Lesson 2" category that there are two entries in the rubric. Your task will be to write additional criteria that relate to the accuracy of information. Your teacher will lead a discussion to help you get started.</p>
          <p>After the class discussion, work with 1-2 teammates to discuss ideas for criteria that should be added to this category.</p>
          <p>Think about what you learned from reading about clinical trials to help you. The handout <em>Evaluating the Clinical Trials</em> may also help.</p>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
